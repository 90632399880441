<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12">
        <v-card>
          <v-card-title> คืนเงินยืม </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="12">
        <v-card>
          <v-card-title>
            <v-btn
              class="mx-3"
              fab
              dark
              color="indigo"
              v-on:click="$router.push('/Finance/Receive/History?Type=Receive')"
            >
              <v-icon dark>fa fa-history</v-icon>
            </v-btn>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col>
        <v-data-table
          :items-per-page="-1"
          :headers="headers"
          :items="desserts"
          :search="search"
          :loading="loading"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              label="ค้นหา"
              class="mx-4"
            ></v-text-field>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn class="mx-2 green" fab dark small @click="Paid(item)">
              <v-icon>fa fa-coins</v-icon>
            </v-btn>
          </template>

          <template v-slot:[`item.Stale`]="{ item }">
            {{ item.Stale}}
          </template>
          <template v-slot:[`item.LendInstallment`]="{ item }">
            {{
              ifNotZero(
                item.Installment.LendInstallment
              )
            }}
          </template>
          <template v-slot:[`item.IncreaseTotal`]="{ item }">
            {{ ifNotZero(item.Installment.IncreaseTotal)}}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      loading: true,
      search: "",
      desserts: [],
      headers: [
        {
          text: "วันที่ลาสุด",
          align: "left",
          sortable: true,
          value: "DateLast",
          width: 100,
        },
        {
          text: "ชื่อ",
          align: "left",
          sortable: true,
          value: "UserName",
          width: 80,
        },
        {
          text: "วงเงิน",
          align: "left",
          sortable: true,
          value: "UserMoneyLimt",
          width: 80,
        },
        {
          text: "ยืมแล้ว",
          align: "right",
          sortable: true,
          value: "LendTotal",
          width: 80,
        },
        {
          text: "คืน",
          align: "right",
          sortable: true,
          value: "RecieveTotal",
          width: 80,
        },
        {
          text: "ค่างวด",
          align: "right",
          sortable: true,
          value: "LendInstallment",
          width: 80,
        },
        {
          text: "ค้างจ่ายค่างวด",
          align: "right",
          sortable: true,
          value: "IncreaseTotal",
          width: 100,
        },
        {
          text: "ค้างจ่าย",
          align: "right",
          sortable: true,
          value: "Stale",
          width: 80,
        },
        { width: 50, text: "", value: "action", sortable: false },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.Select();
    });
  },
  methods: {
    ifNotZero(value) {
      return value <= 0 ? 0 : value;
    },
    async Select() {
      this.loading = true;
      const result = await axios.get("/api/v1/select/Recieve/User");
      if (result.status == 200) {
        this.desserts = result.data;
      }
      this.loading = false;
    },
    Paid(v) {
      this.$router.push("/Finance/Receive/Add?UserId=" + v.UserId);
    },
  },
};
</script>
